import React, { FC, PropsWithChildren } from "react";
import styled from "@emotion/styled";

const StyledFrameWr = styled.div`
  display: inline-block;
  position: relative;
  box-sizing: content-box !important;

  width: 215px;
  height: 465px;
  background: black;
  border-radius: 34px;
  padding: 45px 10px;

  .overflow {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 34px;
    overflow: hidden;
  }

  .speaker {
    height: 8px;
    width: 56px;
    left: 50%;
    position: absolute;
    top: 25px;
    margin-left: 0px;
    background: #171818;
    z-index: 1;
    border-radius: 8px;
  }

  .camera {
    height: 18px;
    width: 18px;
    left: 86px;
    position: absolute;
    top: 18px;
    background: #212b36;
    z-index: 1;
    border-radius: 100%;

    &:before {
      content: "";
      height: 8px;
      width: 8px;
      left: -22px;
      position: absolute;
      top: 5px;
      background: #212b36;
      z-index: 1;
      border-radius: 100%;
    }
  }

  .sensors {
    height: 10px;
    width: 10px;
    left: 120px;
    position: absolute;
    top: 22px;
    background: #1d233b;
    z-index: 1;
    border-radius: 100%;

    &:before {
      content: "";
      height: 10px;
      width: 10px;
      left: 18px;
      position: absolute;
      top: 0;
      background: #1d233b;
      z-index: 1;
      border-radius: 100%;
    }
  }

  .sleep {
    width: 2px;
    height: 56px;
    background: black;
    position: absolute;
    top: 288px;
    right: -2px;
  }

  .volume {
    width: 2px;
    height: 120px;
    background: black;
    position: absolute;
    top: 168px;
    left: -2px;

    &:before {
      content: "";
      top: 168px;
      width: 2px;
      position: absolute;
      left: 0;
      background: black;
      height: 56px;
    }
  }

  .inner {
    width: 100%;
    height: calc(100% - 8px);
    position: absolute;
    top: 2px;
    content: "";
    left: 0px;
    border-radius: 34px;
    border-top: 2px solid #9fa0a2;
    border-bottom: 2px solid #9fa0a2;
    background: black;
    z-index: 0;
    box-shadow: inset 0 0 6px 0 #ffffff80;
  }

  .shadow {
    box-shadow:
      inset 0 0 10px 0 white,
      inset 0 0 50px 0 #ffffff80,
      0 0 20px 0 white,
      0 0 10px 0 #ffffff80;
    height: 101%;
    position: absolute;
    top: -0.5%;
    content: "";
    width: calc(100% - 20px);
    left: 10px;
    border-radius: 38px;
    z-index: 5;
    pointer-events: none;
  }

  .screen {
    position: relative;
    height: 100%;
    border-radius: 14px;
    box-shadow: none;
  }
`;

const MobileNoteFrame: FC<PropsWithChildren<{}>> = ({ children }) => {
  return (
    <StyledFrameWr>
      <div className="inner"></div>
      <div className="overflow">
        <div className="shadow"></div>
      </div>
      <div className="speaker"></div>
      <div className="sleep"></div>
      <div className="volume"></div>
      <div className="camera"></div>
      <div className="screen">{children}</div>
    </StyledFrameWr>
  );
};

export default MobileNoteFrame;
