import { ButtonGroup, Stack, styled, Typography } from "@mui/material";
import Router from "next/router";
import React, { FC, useState } from "react";
import { Button, InputGroup, Slider } from "../../../../../../ui";
import { downloadBlob, generateSVG } from "../../../preview/uploadSvg";

interface Props {
  state: any;
  value: string;
  qrName: string;
  isChanged?: boolean;
  previousState?: () => void;
  resetToDefault?: () => void;
  closeExportOptions: () => void;
}

const FileTypeButton = styled(`button`)`
  width: 100%;
  font-family: Poppins;
  height: 40px;
  border: none;
  border-radius: 10px;
  font-weight: 600;
`;

const Download: FC<Props> = ({ state, closeExportOptions, value, qrName }) => {
  const [exporting, setExporting] = useState(false);
  const [exportValue, setExport] = useState(0);
  const [resolution, setResolution] = useState(300);
  const { id, ...r } = state;

  const exportQr = async () => {
    setExporting(true);
    await generateSVG(
      r,
      value,
      resolution,
      exportValue === 0 ? "png" : "svg",
      downloadBlob,
      qrName || "Empty Title"
    );
    closeExportOptions();
  };

  const onChangeResolution = (e: any, v: any) => {
    setResolution(v);
  };

  return (
    <Stack width="300px" rowGap={1}>
      <Stack>
        <Stack justifyContent="space-between">
          <Typography fontSize={13}>
            {resolution} x {resolution} px
          </Typography>
        </Stack>
        <Slider
          labels={[
            {
              position: 0,
              label: "Low Resolution",
            },

            {
              position: 80,
              label: "High Resolution",
            },
          ]}
          onChange={onChangeResolution}
          defaultValue={resolution}
          aria-label="Default"
          step={100}
          min={300}
          max={2000}
          valueLabelDisplay="auto"
        />
      </Stack>

      <ButtonGroup
        sx={{
          mb: 4,
          border: "1px solid #022B51",
          borderRadius: "10px",
          mt: 4,
        }}
      >
        <FileTypeButton
          onClick={() => setExport(0)}
          sx={{
            backgroundColor: exportValue === 0 ? "#022B51" : "initial",
            color: exportValue === 0 ? "#fff" : "#022B51",
          }}
        >
          PNG
        </FileTypeButton>
        <FileTypeButton
          onClick={() => setExport(1)}
          sx={{
            backgroundColor: exportValue === 1 ? "#022B51" : "initial",
            color: exportValue === 1 ? "#fff" : "#022B51",
          }}
        >
          SVG
        </FileTypeButton>
      </ButtonGroup>

      <Button
        fullWidth={true}
        isLoading={exporting}
        variant="primary"
        onClick={exportQr}
      >
        Export
      </Button>
    </Stack>
  );
};

export default Download;
