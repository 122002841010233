import React, { FC, PropsWithChildren } from "react";
import DashboadContainer from "../page/dashboard/dashboard-container";
import Script from "next/script";
import MixpanelProvider from "../page/dashboard/common/useMixpanel";
import {
  Container,
  GlobalStyles,
  Stack,
  ThemeProvider as ThemeProviderMui,
  Typography,
} from "@mui/material";
import BaseLayout from "./BaseLayout";
import { useRouter } from "next/router";
import useActiveDashboard from "../../utils/useActiveDashboard";
import Notification from "../../ui/Notification";
import Link from "next/link";
import { useMeQuery } from "../../generated/graphql";
import TrialBanner from "../reusable/banners/TrialBanner";
import ConfirmAlert from "../reusable/banners/ConfirmAlert";
import useInfoBanner from "../reusable/banners/useInfoBanner";

export const DashboardContainerLayout: FC<PropsWithChildren<{}>> = ({
  children,
}) => {
  const dashboard = useActiveDashboard();
  const isQrLinkDashboard = dashboard === "link";

  const { confirmationBanner, trialBanner } = useInfoBanner(false);

  return (
    <>
      <BaseLayout>
        <ConfirmAlert enabled={confirmationBanner} />
        <TrialBanner enabled={trialBanner} isPublic={false} />
        <MixpanelProvider>
          <Container
            maxWidth={!isQrLinkDashboard ? "lg" : false}
            sx={{
              pt: 0,
              px: [0, 0, 0, 0],
            }}
          >
            <DashboadContainer>{children}</DashboadContainer>
          </Container>
        </MixpanelProvider>
      </BaseLayout>
    </>
  );
};

export default DashboardContainerLayout;
