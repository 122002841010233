import { Box, Typography } from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import Modal from "../../../../reusable/modals/Modal";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Button } from "../../../../../ui";
import { getQrValue, useCopy } from "../../../../../utils";
import { createQrThumbnail } from "../../common/qrgenerator/generator";
import { useServerQr } from "../../bioqr/state";
import Router from "next/router";
import Download from "../../single-link/qr/qrlink/DownloadModal";
import { useModal } from "../../../../../ui/Modal";

interface Props {
  pageName: string;
}

const ShareModal: FC<Props> = ({ pageName }) => {
  const { renderModal, openModal, closeModal } = useModal();
  const { copied, copy } = useCopy();

  const qr = useServerQr() as any;
  const [rf, setR] = useState(null);

  useEffect(() => {
    if (rf) {
      createQrThumbnail({
        previewElementHeight: 110,
        previewElementWidth: 110,
        previewElementId: "ga",
        value: getQrValue(pageName || "", true, ""),
        state: {
          ...qr,
          frame: {
            id: -1,
          },
        },
      });
    }
  }, [rf]);

  const _copy = () => {
    copy(getQrValue(pageName || ""));
  };

  const onDownloadClick = () => {
    openModal(
      <Download
        closeExportOptions={closeModal}
        state={qr}
        value={getQrValue(pageName || "", true, "")}
        qrName={pageName || ""}
      />
    );
  };

  return (
    <>
      {renderModal()}
      <Modal
        title={"Share your Bio Page"}
        sx={{
          maxWidth: "370px",
        }}
      >
        <Typography variant="h4" mt={1} mb={1} fontSize={14}>
          {getQrValue(pageName || "")}
        </Typography>
        <Box
          onClick={_copy}
          sx={{
            display: "flex",
            alignItems: "center",
            py: "5px",
            px: "14px",
            borderRadius: "80px",
            cursor: "pointer",
            backgroundColor: "#D9D9D9",
          }}
        >
          <ContentCopyIcon
            sx={{
              fontSize: copied ? "14px" : "14px",
              "&:hover": {
                color: "system.main",
              },
            }}
          />
          <Typography
            sx={{
              fontSize: "10px",
              color: "#515151",
              ml: "5px",
            }}
          >
            {copied ? "Copied" : "Copy URL to clipboard"}
          </Typography>
        </Box>
        <Typography mt={3} fontWeight={600}>
          Your BioQR
        </Typography>
        <Box id="ga" ref={(r) => setR(r as any)} />
        <Button
          onClick={() => Router.push("/qrcode")}
          variant="link"
          sx={{
            width: "100%",
            fontWeight: 600,
          }}
        >
          Design your BioQR →
        </Button>
        <Button variant="primary" fullWidth={true} onClick={onDownloadClick}>
          ↓ Download BioQR
        </Button>
      </Modal>
    </>
  );
};

export default ShareModal;
