import React, { FC, useEffect, useState } from "react";
import { useAppearance } from "../state";
import * as THEMES from "../customization/themes";
import { getButtonTheme } from "../themes/buttons";
import {
  LinkType,
  PageButtonModel,
  useMeQuery,
} from "../../../../../generated/graphql";
import PageHeader from "./PageHeader";
import MobileFrame from "./frames/MobileFrame";
import BioLinks from "./links";
import PageBackground from "./PageBackground";
import { styled, Box, Stack } from "@mui/material";
import { useBioLinks } from "../../link-creator/state";
import { usePageSettings } from "../../settings/state";
import Banner from "./Banner";
import ContactPage from "../../../customer-page/ContactPage";
import Link from "./links/link-types/Link";
import BottomLogo from "./BottomLogo";

interface Props {
  frame: any;
}
/**
 * ${(props) => props.theme.media.md`
    height: 100%;
    max-height: 620px;
    min-height:600px; 
 */

const StyledPageWr = styled(Box)<any>(
  ({ theme, font, button }) => `
  position: relative;
  overflow-y: auto;
  height: auto;
  width: 100%;
  height: 100%;
  min-height: 580px;
  max-height: 580px;
  padding: 12px;
  border-radius: 14px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;


  font-family: ${font};

  &::-webkit-scrollbar-track {
    background-color: #f7f7f7;
    border-radius: 99px;
  }

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 99px;
    background-color: #3d8ffa;
    border: 2px #f7f7f7 solid;
  }

  ${
    theme === "Custom"
      ? ""
      : THEMES[theme as keyof typeof THEMES]?.style || THEMES["Default"].style
  }

  ${
    button?.id &&
    `
    .button {
        ${getButtonTheme(button.id)};
    }`
  }
`
);

const Mobile: FC<Props> = ({ frame }) => {
  const [terms, setTerms] = useState(false);
  const [contactPage, setContactPage] = useState(false);
  const { data } = useMeQuery({ fetchPolicy: "cache-only" });

  const links = useBioLinks();
  const appearance = useAppearance();
  const settings = usePageSettings();

  const qr = data?.me.page.qr;

  //const [sensitivePreview, setSentisivPreview] = useState(!!sensitive);

  const social = appearance.social;
  const socialIcons =
    links.find(
      (l) => l.type === LinkType.SocialIcon && l.title === "Social Badges"
    )?.children[0].row || [];

  return (
    <MobileFrame type={frame}>
      <PageBackground
        isPreview={true}
        theme={
          appearance?.theme?.id === "Custom" ? appearance?.theme : undefined
        }
      />

      <StyledPageWr
        font={appearance.font.family}
        theme={appearance.theme.id as any}
        button={appearance.buttons}
      >
        {contactPage ? (
          <ContactPage
            profileImage={appearance.profileImage}
            onCloseClick={() => setContactPage(false)}
            qr={qr as any}
            pageName={data?.me.page.pagename || ""}
            contactColor={social?.iconsColor || "#000000"}
            closeColor={appearance.buttons?.custom?.color.textColor || "white"}
            contact={settings?.contact ?? {}}
          >
            {socialIcons
              .filter((l) => l.url.length > 0 && l.id && l.title.length > 0)
              .map((link, key) => (
                <Link
                  key={key}
                  isContactLink={true}
                  link={{
                    url: link.url,
                    thumbnail: `customIcon:${link.title}:${social?.iconsColor}`,
                    title: link.title,
                  }}
                  font={appearance.font}
                  button={appearance?.buttons}
                  iconColor={
                    appearance.buttons?.custom?.color.textColor || "#000000"
                  }
                />
              ))}
          </ContactPage>
        ) : (
          <>
            <PageHeader
              bio={appearance.bio}
              profileImage={appearance.profileImage}
              title={appearance.title}
            />
            <Box
              sx={{
                width: "100%",
                flex: "1",
              }}
            >
              <BioLinks
                links={links}
                font={appearance.font}
                button={appearance?.buttons}
                settings={settings as any}
                setContactPage={() => setContactPage(true)}
                iconsColor={social?.iconsColor || "#000000"}
              />
            </Box>
            <BottomLogo disabled={appearance.logo} />
          </>
        )}
      </StyledPageWr>
      {settings?.infoBanner && <Banner infoBanner={settings?.infoBanner} />}
    </MobileFrame>
  );
};

export default Mobile;
