import React, { FC, PropsWithChildren } from "react";
import styled from "@emotion/styled";

const StyledFrameWr = styled.div`
  display: inline-block;
  position: relative;
  box-sizing: content-box !important;

  width: 290px;
  height: 580px;
  padding: 13px;
  background: #fdfdfd;
  box-shadow: inset 0 0 11px 0 black;
  border-radius: 40px;

  .overflow {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 40px;
    overflow: hidden;
  }

  .shadow {
    border-radius: 100%;
    width: 90px;
    height: 90px;
    position: absolute;
    background: radial-gradient(
      ellipse at center,
      rgba(0, 0, 0, 0.6) 0%,
      rgba(255, 255, 255, 0) 60%
    );
  }

  .shadow--tl {
    top: -20px;
    left: -20px;
  }

  .shadow--tr {
    top: -20px;
    right: -20px;
  }

  .shadow--bl {
    bottom: -20px;
    left: -20px;
  }

  .shadow--br {
    bottom: -20px;
    right: -20px;
  }

  &:before {
    width: calc(100% - 10px);
    height: calc(100% - 10px);
    position: absolute;
    top: 5px;
    content: "";
    left: 5px;
    border-radius: 35px;
    background: black;
    z-index: 1;
  }

  .screen {
    border-radius: 30px;
    box-shadow: none;
  }

  .volume,
  .volume:before,
  .volume:after,
  .sleep {
    width: 3px;
    background: #b5b5b5;
    position: absolute;
  }

  .volume {
    left: -3px;
    top: 116px;
    height: 32px;

    &:before {
      height: 62px;
      top: 62px;
      content: "";
      left: 0;
    }

    &:after {
      height: 62px;
      top: 140px;
      content: "";
      left: 0;
    }
  }

  .sleep {
    height: 96px;
    top: 200px;
    right: -3px;
  }

  .camera {
    width: 6px;
    height: 6px;
    top: 9px;
    border-radius: 100%;
    position: absolute;
    left: 154px;
    background: #0d4d71;
  }

  .speaker {
    height: 6px;
    width: 60px;
    left: 50%;
    position: absolute;
    top: 9px;
    margin-left: -30px;
    background: #171818;
    border-radius: 6px;
  }

  .notch {
    position: absolute;
    width: 130px;
    height: 15px;
    top: 13px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 4;
    background: black;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }

  .screen {
    width: 100%;
    position: relative;
    height: 100%;
    z-index: 3;
    background: white;
    overflow: hidden;
    display: block;
    box-shadow: 0 0 0 3px #111;
  }

  .top-bar,
  .bottom-bar {
    height: 3px;
    background: black;
    width: 100%;
    display: block;
  }

  .middle-bar {
    width: 3px;
    height: 4px;
    top: 0px;
    left: 90px;
    background: black;
    position: absolute;
  }
`;

const MobileIphoneFrame: FC<PropsWithChildren<{}>> = ({ children }) => {
  return (
    <StyledFrameWr>
      <div className="notch"></div>
      <div className="sleep"></div>
      <div className="volume"></div>
      <div className="overflow">
        <div className="shadow shadow--tr"></div>
        <div className="shadow shadow--tl"></div>
        <div className="shadow shadow--br"></div>
        <div className="shadow shadow--bl"></div>
      </div>
      <div className="screen">{children}</div>
    </StyledFrameWr>
  );
};

export default MobileIphoneFrame;
