import React, { FC, useState } from "react";
import { useCopy, getQrValue } from "../../../../../utils";

import { useMeQuery } from "../../../../../generated/graphql";
import Mobile from "./Mobile";
import {
  Box,
  Button,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { InputGroup } from "../../../../../ui";
import LaunchIcon from "@mui/icons-material/Launch";
import Router from "next/router";
import Link from "next/link";
import IosShareIcon from "@mui/icons-material/IosShare";
import { useModal } from "../../../../../ui/Modal";
import Modal from "../../../../reusable/modals/Modal";
import ShareModal from "./ShareModal";

interface Props {}

const PagePreviewPanel: FC<Props> = ({}) => {
  const { openModal, renderModal } = useModal();
  const [frame, setFrame] = useState<any>("iphone");
  const { copied, copy } = useCopy();
  const { data } = useMeQuery({ fetchPolicy: "cache-only" });

  const _copy = () => {
    copy(getQrValue(data?.me.page.pagename || ""));
  };

  const openShareModal = () => {
    openModal(<ShareModal pageName={data?.me.page.pagename || ""} />);
  };

  return (
    <>
      {renderModal()}

      <Stack rowGap={4} alignItems="center" width="100%">
        <Stack
          sx={{
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
            backgroundColor: "background.default",
            p: 2,
            borderRadius: "15px",
          }}
        >
          <Typography
            sx={{
              color: "primary.main",
              fontSize: 14,
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "pre",
              textDecoration: "underline",
              width: "80%",
            }}
          >
            {copied
              ? "Copied to clipboard"
              : getQrValue(data?.me.page.pagename || "")}
          </Typography>

          <Tooltip title="Copy to clipboard">
            <Box
              onClick={_copy}
              sx={{
                display: "flex",
                alignItems: "center",
                py: "5px",
                px: "14px",
                borderRadius: "80px",
                cursor: "pointer",
                backgroundColor: "#D9D9D9",
              }}
            >
              <ContentCopyIcon
                sx={{
                  fontSize: copied ? "14px" : "14px",
                  "&:hover": {
                    color: "system.main",
                  },
                }}
              />
              <Typography
                sx={{
                  fontSize: "10px",
                  color: "#515151",
                  ml: "5px",
                }}
              >
                Copy
              </Typography>
            </Box>
          </Tooltip>
        </Stack>
        <Mobile frame={frame} />
        <Stack
          sx={{
            width: "100%",
            alignItems: "center",
            rowGap: 2,
          }}
        >
          <Link
            href={getQrValue(data?.me.page.pagename || "") + "?preview=true"}
            target="_blank"
            passHref
          >
            <Tooltip title="Preview in new tab">
              <Typography
                component="a"
                target="_blank"
                rel="noopener noreferrer"
                color="primary"
                fontWeight={500}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  textDecoration: "none",
                }}
              >
                <LaunchIcon
                  fontSize="small"
                  color="primary"
                  sx={{
                    cursor: "pointer",
                    mr: "8px",
                    "&:hover": {
                      color: "primary.hover",
                    },
                  }}
                />
                Preview Bio Page
              </Typography>
            </Tooltip>
          </Link>

          <Button
            variant="primary"
            fullWidth={true}
            startIcon={<IosShareIcon />}
            onClick={openShareModal}
          >
            Share
          </Button>
        </Stack>
      </Stack>
    </>
  );
};

export default PagePreviewPanel;
