import { Box } from "@mui/material";
import React, { FC } from "react";
import FreeLogoBanner from "../../../../reusable/FreeLogoBanner";

interface Props {
  disabled: boolean;
}

const BottomLogo: FC<Props> = ({ disabled }) => {
  return (
    <Box
      sx={{
        position: "sticky",
        top: "15px",
        visibility: disabled ? "initial" : "hidden",
      }}
    >
      <FreeLogoBanner />
    </Box>
  );
};

export default BottomLogo;
