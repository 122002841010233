import { makeVar, useReactiveVar } from "@apollo/client";
import { useMePageQuery } from "../../../../../generated/graphql";
import { initialState as is } from "../../common/qrgenerator/initialState";

export const useServerQr = () => {
  const { data } = useMePageQuery({
    fetchPolicy: "cache-only",
  });
  if (data?.me.page) {
    const { tags, ...qr } = data.me.page.qr;
    return qr;
  }
  return is;
};

export const useQrTags = () => {
  const { data } = useMePageQuery({
    fetchPolicy: "cache-only",
  });
  return data?.me.page.qr.tags;
};

export const qrUtm = makeVar<string>("");
