import { makeVar, useReactiveVar } from "@apollo/client";
import { useMemo } from "react";
import client from "../../../../../config/apollo";
import {
  InfoBannerColors,
  PageSettingsModel,
  Position,
  Terms,
  EmailProviders,
  useMePageQuery,
  useMeQuery,
} from "../../../../../generated/graphql";

export const DEFAULT_INFOBANNER_STATE_COLORS: InfoBannerColors = {
  title: "#022B51",
  content: "#304263",
  buttonBg: "#EEEEEE",
  buttonLabel: "#0F75FB",
  background: "#D8D8D8",
};

export const DEFAULT_INFOBANNER_STATE: PageSettingsModel["infoBanner"] = {
  title: "",
  content: "",
  buttonLabel: "",
  buttonUrl: "",
  font: "",
  colors: DEFAULT_INFOBANNER_STATE_COLORS,
};

export const DEFAULT_SEO_STATE: PageSettingsModel["seo"] = {
  title: "",
  description: "",
};

export const DEFAULT_EMAIL_SIGNUP_TERMS_STATE: Terms = {
  linkLabel: "",
  termsLink: "",
  termsLabel: "",
};

export const DEFAULT_EMAIL_SIGNUP_STATE: PageSettingsModel["emailSignup"] = {
  buttonText: "",
  successMessage: "",
  position: Position.Top,
  terms: null,
  storage: EmailProviders.Joyqr,
  googleSheetUrl: null,
  mailchimpApi: null,
  mailchimpList: null,
  colors: {
    buttonColor: "#0F75FB",
    buttonTextColor: "#ffffff",
  },
};

export const DEFAULT_SMS_SIGNUP_STATE: PageSettingsModel["smsSignup"] = {
  buttonText: "",
  successMessage: "",
  position: Position.Top,
  terms: null,
  storage: EmailProviders.Joyqr,
  googleSheetUrl: null,
  colors: {
    buttonColor: "#0F75FB",
    buttonTextColor: "#ffffff",
  },
};

export const usePageSettings = () => {
  const { data } = useMePageQuery({
    fetchPolicy: "cache-only",
  });
  return data?.me.page?.settings;
};

export const usePageSettingsLocalUpdate = () => {
  const { data } = useMeQuery({
    fetchPolicy: "cache-only",
  });
  return {
    update: useMemo(
      () =>
        ({ key, value }: { key: string; value: any }) => {
          client.cache.modify({
            id: `PageModel:${data?.me.page.id}`,
            fields: {
              settings(c) {
                return {
                  ...c,
                  [key]: value,
                };
              },
            },
          });
        },
      []
    ),
    state: data?.me.page.settings || defaultState,
  };
};

const defaultState: PageSettingsModel = {} as PageSettingsModel;
