import React, { FC, PropsWithChildren } from "react";
import MobileIphoneFrame from "./MobileIphoneFrame";
import MobileNoteFrame from "./MobileNoteFrame";
interface Props {
  type: "iphone" | "note";
}

const MobileFrame: FC<PropsWithChildren<Props>> = ({
  children,
  type = "iphone",
}) => {
  const Frame = type === "iphone" ? MobileIphoneFrame : MobileNoteFrame;

  return <Frame>{children}</Frame>;
};

export default MobileFrame;
